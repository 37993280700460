<template>
  <ul class="menu-nav">
    <template v-for="aMenu in aMenus">
      <router-link
        v-if="aMenu.submenus.length == 0"
        :to="aMenu.url"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-bind:key="aMenu.url"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon icon-lg" :class="aMenu.icono"></i>
            <span class="menu-text">{{ aMenu.titulo }}</span>
          </a>
        </li>
      </router-link>
      <li
        v-else
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren(aMenu.url) }"
        v-bind:key="aMenu.url"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon icon-lg" :class="aMenu.icono"></i>
          <span class="menu-text">{{ aMenu.titulo }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">{{ aMenu.titulo }}</span>
              </span>
            </li>
            <router-link
              v-for="aSubmenu in aMenu.submenus"
              :to="aSubmenu.url"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-bind:key="aSubmenu.url"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{ aSubmenu.titulo }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import KTUtil from "@/assets/js/components/util";
import { GET_MENU } from "@/core/services/store/global.module";
// eslint-disable-next-line
import { _ } from 'vue-underscore';
export default {
  data() {
    return {
      aMenus: [],
    };
  },
  name: "KTMenu",
  created() {
    this.createMenu();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    async createMenu() {
      let oVue = this;
      const oMenu = await oVue.getMenu();
      const oMenuPadre = oMenu.filter((m) => m.padre == 0);
      const oSubmenu = oMenu.filter((m) => m.padre != 0);
      oVue.aMenus = oMenuPadre.map((p) => {
        p.submenus = oSubmenu.filter((m) => m.padre == p.id);
        return p;
      });
    },
    async getMenu() {
      KTUtil.setLoading();
      let oVue = this;
      const aMenuReponse = await oVue.$store.dispatch(
        GET_MENU,
        oVue.currentUserPersonalInfo.usuarioID
      );
      KTUtil.removeLoading();

      return aMenuReponse;
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
};
</script>
