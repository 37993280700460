<template>
  <b-modal
    id="updatePasswordModal"
    title="Cambio de contraseña"
    size="md"
    hide-button-size="sm"
    centered
  >
    <template #modal-header>
      <h3>Cambio de contraseña</h3>
    </template>
    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        class="btn btn-twitter btn-bold"
        @click="updatePassword()"
      >
        Cambiar
      </b-button>
      <b-button size="sm" class="btn btn-youtube btn-bold" @click="cancel()">
        Cancelar
      </b-button>
    </template>
    <v-form v-model="form">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="currentPassword"
            label="Contraseña actual"
            :type="showCurrentPassword ? 'text' : 'password'"
            :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showCurrentPassword = !showCurrentPassword"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="newPassword"
            label="Nueva contraseña"
            :rules="[rules.required, rules.min]"
            :type="showNewPassword ? 'text' : 'password'"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showNewPassword = !showNewPassword"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="reNewPassword"
            label="Repita nueva contraseña"
            :rules="[rules.required, rules.min]"
            :type="showReNewPassword ? 'text' : 'password'"
            :append-icon="showReNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showReNewPassword = !showReNewPassword"
          />
        </v-col>
      </v-row>
    </v-form>
  </b-modal>
</template>
<script>
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import { UPDATE_PASSWORD } from "@/core/services/store/profile.module";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "UpdatePassword",
  props: {
    display: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: false,
      showCurrentPassword: false,
      currentPassword: "",
      showNewPassword: false,
      newPassword: "",
      showReNewPassword: false,
      reNewPassword: "",
      rules: {
        required: (value) => !!value || "Requerido.",
        min: (v) => v.length >= 8 || "Min. 8 caracteres",
      },
    };
  },
  methods: {
    async updatePassword() {
      if (!this.form) {
        Swal.fire({
          title: "Advertencia!",
          text: "Debe llenar todos los campos del formulario.",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });

        return;
      }

      if (this.newPassword !== this.reNewPassword) {
        Swal.fire({
          title: "Error!",
          text: "Las contraseñas ingresadas no concuerdan",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });

        return;
      }

      const { result, message: errorMessage } = await this.$store.dispatch(
        UPDATE_PASSWORD,
        {
          password: this.newPassword,
          currentPassword: this.currentPassword,
        }
      );

      if (!result) {
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });

        return;
      }

      KTUtil.setLoading();

      await this.$store.dispatch(LOGOUT);

      KTUtil.removeLoading();

      Swal.fire({
        title: "Enhorabuena!",
        text: "Contraseña actualizada satisfactoriamente, se procedera a cerrar la sesión para re-ingresar sus credenciales.",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      }).then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>
